var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-xl":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"lg12":"","sm12":"","xs12":""}},[_c('Search'),_c('v-btn',{directives:[{name:"hasPrivilleges",rawName:"v-hasPrivilleges",value:('create_type_solicitation'),expression:"'create_type_solicitation'"}],staticClass:"mb-3",attrs:{"depressed":"","color":"success"}},[_c('router-link',{staticClass:"white--text",attrs:{"to":{ name: 'dashboard-tipo-pedido-novo' },"title":"Adicionar"}},[_c('v-icon',[_vm._v("add")]),_vm._v("Novo ")],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.table.header,"items":_vm.table.content,"item-key":'id',"loading":_vm.table.isLoading,"items-per-page":_vm.table.paginate.inputPage.size,"options":_vm.pagination,"server-items-length":_vm.table.total,"footer-props":{ 'items-per-page-options': [10, 20, 30, 40, 50, 100], showFirstLastPage: true, showCurrentPage: true }},on:{"update:options":function($event){_vm.pagination=$event},"click:row":_vm.goToForm},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.value))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{directives:[{name:"hasPrivilleges",rawName:"v-hasPrivilleges",value:('update_type_solicitation'),expression:"'update_type_solicitation'"}],attrs:{"to":{
              name: 'dashboard-tipo-pedido-editar',
              params: { id: item.id }
            },"title":"Visualizar"}},[_c('v-icon',[_vm._v("visibility")])],1),_c('v-btn',{attrs:{"icon":"","primary":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openDialogDelete(item)}}},[_c('v-icon',[_vm._v("delete")])],1)]}}])}),(_vm.dialogDelete.open)?_c('v-dialog',{attrs:{"width":"300"},model:{value:(_vm.dialogDelete.open),callback:function ($$v) {_vm.$set(_vm.dialogDelete, "open", $$v)},expression:"dialogDelete.open"}},[_c('v-card',{staticClass:"app-card mb-0"},[_c('v-card-title',{staticClass:"app-card-title"},[_c('h4',{staticClass:"text"},[_c('span',[_vm._v("Excluir Recurso")])])]),_c('v-card-text',{staticClass:"app-card-content"},[_c('p',[_vm._v("Deseja excluir o recurso "+_vm._s(_vm.dialogDelete.data.name)+" ?")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"error","text":""},on:{"click":function($event){$event.preventDefault();return _vm.closeDialogDelete.apply(null, arguments)}}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"depressed":"","color":"error","type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.doDeleteItem.apply(null, arguments)}}},[_vm._v(" Deletar ")])],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }