<template>
  <v-container grid-list-xl>
    <v-layout
      row
      wrap
    >
      <v-flex
        lg12
        sm12
        xs12
      >
        <Search />
        <v-btn
          v-hasPrivilleges="'create_type_solicitation'"
          depressed
          color="success"
          class="mb-3"
        >
          <router-link
            :to="{ name: 'dashboard-tipo-pedido-novo' }"
            class="white--text"
            title="Adicionar"
          >
            <v-icon>add</v-icon>Novo
          </router-link>
        </v-btn>
        <v-data-table
          class="elevation-1"
          :headers="table.header"
          :items="table.content"
          :item-key="'id'"
          :loading="table.isLoading"
          :items-per-page="table.paginate.inputPage.size"
          :options.sync="pagination"
          :server-items-length="table.total"
          :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, 100], showFirstLastPage: true, showCurrentPage: true }"
          @click:row="goToForm"
        >
          <template v-slot:item.value="{ item }">
            {{ item.value | currency }}
          </template>
          <template v-slot:item.actions="{ item }">
            <router-link
              v-hasPrivilleges="'update_type_solicitation'"
              :to="{
                name: 'dashboard-tipo-pedido-editar',
                params: { id: item.id }
              }"
              title="Visualizar"
            >
              <v-icon>visibility</v-icon>
            </router-link>
            <v-btn
              icon
              primary
              @click.prevent.stop="openDialogDelete(item)"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-dialog
          v-if="dialogDelete.open"
          v-model="dialogDelete.open"
          width="300"
        >
          <v-card class="app-card mb-0">
            <v-card-title class="app-card-title">
              <h4 class="text">
                <span>Excluir Recurso</span>
              </h4>
            </v-card-title>

            <v-card-text class="app-card-content">
              <p>Deseja excluir o recurso {{ dialogDelete.data.name }} ?</p>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                outlined
                color="error"
                text
                @click.prevent="closeDialogDelete"
              >
                Fechar
              </v-btn>
              <v-btn
                depressed
                color="error"
                type="button"
                @click.prevent="doDeleteItem"
              >
                Deletar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import Search from './Search';

  export default {
    components: {
      Search
    },
    data: () => ({
      pagination: null,
      dialogDelete: {
        open: false,
        data: null
      }
    }),
    computed: {
      ...mapState('tipoPedido', ['table'])
    },
    watch: {
      pagination: {
        handler() {
          this.changePagination();
        },
        deep: true
      }
    },
    destroyed() {
      this.clear();
    },
    methods: {
      changePagination() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
        const paginate = {
          ...this.table.paginate,
          inputPage: {
            page: page,
            size: itemsPerPage,
            order: sortBy.length > 0 ? sortBy[0] : 'id',
            descingOrder: sortDesc.length > 0 ? sortDesc[0] : true
          }
        };
        this.changePage(paginate);
        this.search();
      },
      goToForm({ id }) {
        this.$router.push({
          name: 'dashboard-tipo-pedido-editar',
          params: { id }
        });
      },
      openDialogDelete(item) {
        this.dialogDelete = {...this.dialogDelete, open: true, data: item}
      },
      closeDialogDelete() {
        this.dialogDelete = {...this.dialogDelete, open: false, data: null}
      },
      doDeleteItem() {
        const { data: { id } } = this.dialogDelete
        this.delete(id)
        this.closeDialogDelete()
      },
      ...mapActions('tipoPedido', { search: 'SEARCH' }),
      ...mapActions('tipoPedido', { clear: 'CLEAR' }),
      ...mapActions('tipoPedido', { changePage: 'CHANGE_PAGE' }),
      ...mapActions('tipoPedido', { delete: 'DELETE' }),
    },
  };
</script>
